import {formatDistanceToNow, isAfter} from 'date-fns';
import {
  CurrentUser,
  MemberProfile,
  AdminProfile,
  CoachProfile,
  DoctorProfile,
  UserRoles,
  TherapistProfile,
  UserAccountType,
  DigitalPracticeAccountType,
  NonAdminUserProfile,
} from '_redux/user/auth/user-auth.types';
import publicIp from 'public-ip';
import {
  StripePaymentPlanName,
  StripePaymentPlanDuration,
} from '_redux/user/payment/user-payment.types';
import {Medication} from '_redux/admin/medications/admin-medication-model.types';
import {Appointment} from '_redux/appointments/appointments.types';
import {zonedTimeToUtc} from 'date-fns-tz';
import {getDefaultNow} from '_services/datetime/datetime.utils';
import {getZonesObject} from '_services/datetime/datetime';
import {MedicationNameList} from '_redux/patients/notes/patients-notes.types';
const hostname = window?.location?.hostname || '';
const uninsured = ['uninsured', 'medicalcare'];

export function isMember(user: CurrentUser): user is MemberProfile {
  return (user as MemberProfile).role === UserRoles.member;
}

export function isAdmin(user: CurrentUser): user is AdminProfile {
  return (user as AdminProfile).role === UserRoles.admin;
}

export function isDoctor(user: CurrentUser): user is DoctorProfile {
  return (user as DoctorProfile).role === UserRoles.prescriber;
}

export function isCoach(user: CurrentUser): user is CoachProfile {
  return (user as CoachProfile).role === UserRoles.mentalhealthcoach;
}

export function isTherapist(user: CurrentUser): user is TherapistProfile {
  return (user as TherapistProfile).role === UserRoles.therapist;
}

export function isMemberOutOfPocketAndDigitalPractice(
  patient: MemberProfile | null,
) {
  if (
    patient?.digitalPracticeAccountType ===
      DigitalPracticeAccountType.outOfPocket ||
    patient?.accountType === UserAccountType.scale
  ) {
    return true;
  } else {
    return false;
  }
}

export const getProviderIP = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    publicIp
      .v4()
      .then(providerIp => {
        resolve(providerIp);
      })
      .catch(() => {
        reject('Error getting IP');
      });
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toFormData = (values: {[key: string]: any}): FormData => {
  const formData = new FormData();
  Object.keys(values).forEach(key => {
    formData.append(key, values[key]);
  });
  return formData;
};

export const timeFromNow = (timestamp: number): string =>
  formatDistanceToNow(timestamp, {addSuffix: true, includeSeconds: true});

export const titleCaseName = (str: string): string => {
  const words = str.toLowerCase().split(' ');
  const result = words.map(val =>
    val.replace(val.charAt(0), val.charAt(0).toUpperCase()),
  );
  return result.join(' ');
};

export const transformNoteMedicationsData = (
  medications: MedicationNameList[],
  role: UserRoles,
  medicationsList: Medication[],
): MedicationNameList[] => {
  const result: MedicationNameList[] = [];
  for (const {
    medicationName,
    medicationInstruction,
    quantity,
    daySupply,
    refillNumber,
    ...rest
  } of medications) {
    const fullMedicationInfo = medicationsList
      .filter(medication =>
        (medicationName as string[]).includes(medication.medicationId),
      )
      .map(({medicationName: medName, medicationId}) => ({
        medicationName: medName,
        medicationId,
      }));

    let data: MedicationNameList = {
      medicationName: fullMedicationInfo,
      medicationInstruction,
      quantity,
      daySupply,
      refillNumber,
    };

    if (role === UserRoles.admin) {
      data = {...data, ...rest};
    }
    result.push(data);
  }
  return result;
};

export function getPaymentPlanRedirectRoute(user: CurrentUser): string {
  let paymentPlanRedirectRoute = '/plans';
  if (isMember(user)) {
    if (user.__subPaymentPlan) {
      paymentPlanRedirectRoute = `/${user.__subPaymentPlan}/register/stripe`;
    } else if (user.paymentPlan) {
      if (
        user.paymentPlan === StripePaymentPlanName.basic ||
        user.paymentPlan.startsWith('uninsured')
      )
        paymentPlanRedirectRoute = '/uninsured/register/stripe';
      if (user.paymentPlan.startsWith('insured'))
        paymentPlanRedirectRoute = '/insured/register/stripe';
      if (user.paymentPlan.startsWith('mindfulness'))
        paymentPlanRedirectRoute = '/mindfulness/register/stripe';
      if (user.paymentPlan === StripePaymentPlanName.basicMarketing)
        paymentPlanRedirectRoute = '/basic/freetrial/register/stripe';
      if (user.paymentPlan === StripePaymentPlanName.mindfulnessMarketing)
        paymentPlanRedirectRoute = '/mindfulness/freetrial/register/stripe';
      if (user.paymentPlan.startsWith('together'))
        paymentPlanRedirectRoute = '/together/register/stripe';
      if (
        user.paymentPlan === StripePaymentPlanName.basicOld ||
        user.paymentPlan === StripePaymentPlanName.medicaidMedicare ||
        user.paymentPlan === StripePaymentPlanName.privateInsurance
      )
        paymentPlanRedirectRoute = '/plans';
    }
  }
  return paymentPlanRedirectRoute;
}

export function getPaymentFormPlanValue(user: MemberProfile | null): string {
  let paymentFormPlanValue = '';
  if (user) {
    if (user.__subPaymentPlan) {
      paymentFormPlanValue = user.__subPaymentPlan;
    } else if (user.paymentPlan) {
      if (user.paymentPlan.startsWith('uninsured'))
        paymentFormPlanValue = 'uninsured';
      if (user.paymentPlan.startsWith('insured'))
        paymentFormPlanValue = 'insured';
      if (user.paymentPlan.startsWith('mindfulness')) {
        if (user.paymentPlan.endsWith('marketting')) {
          paymentFormPlanValue = '';
        } else {
          paymentFormPlanValue = 'mindfulness';
        }
      }
      if (user.paymentPlan.startsWith('together'))
        paymentFormPlanValue = 'together';
    }
  }
  return paymentFormPlanValue;
}

export function getDefaultPaymentFormDurationValue(
  user: MemberProfile | null,
): string {
  if (user?.paymentPlan) {
    if (/together/.test(user.paymentPlan))
      return StripePaymentPlanDuration.weekly;
    if (/biweekly/.test(user.paymentPlan))
      return StripePaymentPlanDuration.biweekly;
    if (user.paymentPlan.endsWith('months'))
      return StripePaymentPlanDuration.biannually;
    if (user.paymentPlan.endsWith('year'))
      return StripePaymentPlanDuration.annually;
    return StripePaymentPlanDuration.monthly;
  }
  return '';
}
export function getPaymentFormDurationValue(
  paymentPlan: string | null,
): string {
  if (paymentPlan) {
    if (/together/.test(paymentPlan)) return StripePaymentPlanDuration.weekly;
    if (uninsured.includes(paymentPlan))
      return StripePaymentPlanDuration.biweekly;
    if (/insured/.test(paymentPlan)) return StripePaymentPlanDuration.monthly;
    if (/(mindfulness|medicalcare_plus_therapy)/.test(paymentPlan))
      return StripePaymentPlanDuration.monthly;
    return StripePaymentPlanDuration.monthly;
  }
  return '';
}

export const getUserRoleForHeaderMenu = (user: CurrentUser): UserRoles => {
  if (!user) return UserRoles.loggedout;
  if ('patientId' in user && !user.verified) return UserRoles.unverified;
  return user.role;
};

export const getIndexPageRedirectRoute = (user: CurrentUser): string => {
  let to = '/dashboard';
  if (!user) to = '/login';
  if (user?.role === UserRoles.admin) to = '/admin/patients';
  if (user?.role === UserRoles.prescriber) to = '/prescriber/appointments';
  if (user?.role === UserRoles.mentalhealthcoach) to = '/coach/appointments';
  if (user?.role === UserRoles.therapist) to = '/therapist/appointments';
  if (user && isMember(user) && !user.verified)
    to = getPaymentPlanRedirectRoute(user);
  return to;
};

const currDay = getDefaultNow();
const zones = getZonesObject(currDay);

export const getFormattedPatientAppointmentDate = ({
  timeOfAppointment,
  dateOfAppointment,
  timeZone,
}: Appointment): Date => {
  const isPM = timeOfAppointment.endsWith('pm');
  let localTime: Date;
  const [hour, minute] = timeOfAppointment.slice(0, -2).split(':');
  let twentyFourHour: string | number = '';
  if (Number(hour) === 12) {
    twentyFourHour = isPM ? hour : '00';
  } else {
    twentyFourHour = isPM ? Number(hour) + 12 : hour;
  }

  try {
    localTime = zonedTimeToUtc(
      new Date(`${dateOfAppointment} ${twentyFourHour}:${minute}`),
      timeZone,
    );
  } catch (error) {
    const tzObject = zones.find(zone => {
      return zone.id === timeZone;
    });
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const formattedOffset = tzObject!.item.offset.replace(':', '');
    localTime = new Date(
      `${dateOfAppointment} ${twentyFourHour}:${minute}${formattedOffset}`,
    );
  }

  return localTime;
};

export const removePatientApptsBeforeNow = (
  appointments: Appointment[],
): Appointment[] => {
  if (appointments.length) {
    const now = new Date(new Date().setHours(0, 0, 0, 0));
    return appointments.filter(appointment => {
      const localTime = getFormattedPatientAppointmentDate(appointment);
      return isAfter(localTime, now);
    });
  } else {
    return appointments;
  }
};

export function getBaseUrl(): string {
  if (process.env.NODE_ENV === 'development' || hostname.includes('localhost'))
    return 'http://localhost:3001';

  if (hostname.includes('stage')) return 'https://app.stage2.wellnite.co';

  if (hostname.includes('wellnite.co')) return 'https://app.wellnite.co';

  return '';
}

export const getClientAppUrl = () => {
  if (process.env.NODE_ENV === 'development' || hostname.includes('localhost'))
    return 'http://localhost:3000';

  if (hostname.includes('stage')) return 'https://stage.wellnite.co';

  if (hostname.includes('wellnite.co')) return 'https://www.wellnite.co';
  return '';
};

export const range = (start: number, end: number): number[] =>
  Array.from({length: end - start}, (_v, k) => k + start);

export const copyToClipboard = (textToCopy: string) => {
  try {
    const textField = document.createElement('textarea');
    textField.innerText = textToCopy;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  } catch (err) {
    console.log(err);
  }
};

export const getDigitalPracticeAccountTypeText = (member: MemberProfile) =>
  `Digital Practice Member ${
    member?.digitalPracticeAccountType === 'out_of_pocket'
      ? ' (Out Of Pocket)'
      : member?.digitalPracticeAccountType === 'insurance' ||
        (!member?.digitalPracticeAccountType &&
          member?.insuranceDetails?.companyType)
      ? ' (In-Network)'
      : ''
  }`;

export const isUnitedStatesUser = (user: NonAdminUserProfile) =>
  user?.countryOfResidence?.code === 'US';
